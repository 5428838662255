import logo from '../static/logo.svg';

function App() {
  return (
    <div className="App">
      <h1> Hello, world! </h1>
    </div>
  );
}

export default App;
